import React from "react";
import Layout from "../../components/layout";
import Retail from "../../components/views/industries/retail";

const RetailPage = () => {
  return (
    <Layout>
      <Retail />
    </Layout>
  );
};

export default RetailPage;
